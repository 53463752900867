<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body">
          <slot name="body">
            <div class="form-group">
              <div class="modal-name pb-2">Add Responsible User</div>
              <label for="newCustomer" />
              <input
                v-model="newResponsibleUser"
                type="text"
                class="form-control"
                placeholder="New Responsible User"
              >
            </div>
            <div style="height: 20px;">
              <p v-show="errors" class="text-danger"> {{ errors }}</p>
              <p v-show="success" class="text-success"> {{ success }}</p>
            </div>
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <button
              type="button"
              class="btn btn-danger"
              @click="this.$emit('close'); clear();"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="clear(); addNewResponsibleUser();"
            >
              Add new responsible user
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddResponsibleUser',
  props: {
    selectedCompany: {
      type: Number,
      required: true
    }
  },
  emits: ['close'],
  data () {
    return {
      newResponsibleUser: '',
      errors: '',
      success: '',
      validResponsibleUser: false,
    }
  },
  methods: {
    async addNewResponsibleUser () {
      if(this.newResponsibleUser) {
        const payload = {
          newResponsibleUser: this.newResponsibleUser,
          companyId: this.selectedCompany
        }
        await this.$store.dispatch('addNewResponsibleUser', {
          payload
        })
          .then(response => {
            if(response){
              this.success = response.data
              this.$store.dispatch('getResponsibleUsers', this.selectedCompany)
              this.newResponsibleUser = ''
            }
          })
          .catch (error => {
            this.errors = error.response.data
          }) 
      } else {
        this.errors = 'New responsible user field cannot be empty'
      }

    },
    clear () {
      this.errors = ''
      this.success = ''
    }
  }
}
</script>

