<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body">
          <slot name="body">
            <div class="modal-name pb-2">Create new project</div>
            <div class="pb-2">Company: {{ currentCompany.companyName }}</div>
            <div class="form-group">
              <label for="Year">Year</label>
              <input
                id="Year"
                v-model="year"
                type="number"
                class="form-control"
                disabled
              >
            </div>
            <div class="form-group">
              <label for="ResponsibleUser">Responsible User</label>
              <select
                id="ResponsibleUser"
                v-model="selectedResponsibleUser"
                class="form-control"
              >
                <option value="">Select Responsible User</option>
                <option
                  v-for="user in responsibleUsers"
                  :key="user"
                  :value="user.id"
                >
                  {{ user.id }} - {{ user.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="Customer">Customer</label>
              <select
                id="Customer"
                v-model="selectedCustomer"
                class="form-control"
              >
                <option value="">Select Customer</option>
                <option
                  v-for="customer in customers"
                  :key="customer"
                  :value="customer.id"
                >
                  {{ customer.id }} - {{ customer.customerName }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="ProjectName">Project Name</label>
              <input
                id="ProjectName"
                v-model="projectName"
                type="text"
                class="form-control"
              >
            </div>
            <div style="height: 20px;">
              <p v-show="errors" class="text-danger"> {{ errors }}</p>
              <p v-show="success" class="text-success"> {{ success }}</p>
            </div>
          </slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button
              type="button"
              class="btn btn-danger"
              @click="this.$emit('close'); clear();"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="addNewProject(); clear();"
            >
              Create new project
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddNewProjectModal',
  props: {
    selectedCompany: {
      type: Number,
      required: true
    },
    responsibleUsers: {
      type: Object,
      required: true
    },
    customers: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  data () {
    return {
      year: (new Date()).getFullYear() - process.env.VUE_APP_YEAR_ZERO,
      selectedResponsibleUser: '',
      projectName: '',
      selectedCustomer: '',
      errors: '',
      success: '',
      validInputs: false,
    }
  },
  computed: {
    currentCompany () {
      const allCompanies = this.$store.state.userCompanies
      const company = allCompanies.find(el => el.id === this.selectedCompany)
      return company
    }
  },
  methods: {
    async addNewProject () {
      if(this.year) {
        const newProject = { 
          companyId: this.selectedCompany,
          year: this.year,
          responsibleUserId: this.selectedResponsibleUser,
          customerId: this.selectedCustomer,
          projectName: this.projectName
        }
        await this.$store.dispatch('addNewProject', {
          newProject
        })
          .then(response => {
            if(response){
              this.success = response.data
              this.$store.dispatch('getProjects', {
                companyId: this.selectedCompany
              })
            }
          })
          .catch (error => {
            this.errors = error.response.data
          }) 
      } else {
        this.errors = 'Customer field cannot be empty'
      }

    },
    clear () {
      this.errors = ''
      this.success = ''
      this.selectedResponsibleUser = ''
      this.projectName = ''
      this.selectedCustomer = ''
    }
  }
}
</script>

