<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body">
          <slot name="body">
            <div class="form-group">
              <div class="modal-name pb-2">Add New Customer</div>
              <label for="newCustomer" />
              <input
                v-model="newCustomerName"
                type="text"
                class="form-control"
                placeholder="New Customer Name"
              >
            </div>
            <div style="height: 20px;">
              <p v-show="errors" class="text-danger"> {{ errors }}</p>
              <p v-show="success" class="text-success"> {{ success }}</p>
            </div>
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <button
              type="button"
              class="btn btn-danger"
              @click="this.$emit('close'); clear();"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="clear(); addNewCustomer();"
            >
              Add new customer
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddCustomerModal',
  props: {
    selectedCompany: {
      type: Number,
      required: true
    }
  },
  emits: ['close'],
  data () {
    return {
      newCustomerName: '',
      errors: '',
      success: '',
      validCustomer: false,
    }
  },
  methods: {
    async addNewCustomer () {
      if(this.newCustomerName) {
        const payload = {
          companyId: this.selectedCompany,
          customerName: this.newCustomerName,
        }
        await this.$store.dispatch('addNewCustomer', {
          payload
        })
          .then(response => {
            if(response){
              this.success = response.data
              this.$store.dispatch('getCustomers', this.selectedCompany)
              this.newCustomerName = ''
            }
          })
          .catch (error => {
            this.errors = error.response.data
          }) 
      } else {
        this.errors = 'Customer field cannot be empty'
      }

    },
    clear () {
      this.errors = ''
      this.success = ''
    }
  }
}
</script>

