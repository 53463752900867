<template>
  <div class="dashboard">
    <nav class="navbar navbar-expand-sm navbar-dark" style="background-color: #3E4756;">
      <a class="navbar-brand" href="/dashboard">
        <img
          src="../assets/pixely-logo-colour.svg"
          width="80"
          height="35"
          class="d-inline-block align-top mr-3"
          alt=""
        >
        Kostentraeger
      </a>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <span class="text-white pr-3">Select Company:</span>
          <select
            v-model="selectedCompany"
            class="form-control-sm company-selector"
          >
            <option
              v-for="(company, index) in allUserCompanies"
              :key="index"
              :value="company.id"
            >
              {{ company.companyName }}
            </option>
          </select>
        </li>
        <li v-if="isAdmin" class="nav-item">
          <button
            type="button"
            class="btn btn-outline-light btn-sm ml-5"
            @click="$router.push('/admin')"
          >
            Admin
          </button>
        </li>
        <li class="nav-item">
          <button
            type="button"
            class="btn btn-danger btn-sm ml-3"
            @click="logOut"
          >
            Logout
          </button>
        </li>
      </ul>
    </nav>
    <div class="container-fluid pt-4">
      <div class="row pb-5">
        <div class="col-xl-8">
          <table class="table table-sm table-striped table-borderless">
            <thead>
              <tr>
                <th
                  scope="col"
                  style="width: 10%"
                  class="text-center"
                >
                  Year
                </th>
                <th
                  scope="col"
                  style="width: 10%"
                  class="text-center"
                >
                  Responsible
                </th>
                <th
                  scope="col"
                  style="width: 10%"
                  class="text-center"
                >
                  Customer
                </th>
                <th
                  scope="col"
                  style="width: 10%"
                  class="text-center"
                >
                  <a
                    href="javascript:;"
                    :class="['sort-column', projectsOrderBy.direction]"
                    @click="sortBy('id')"
                  >
                    Number
                  </a>
                </th>
                <th scope="col" style="width: 5%">Active</th>
                <th scope="col" style="width: 40%">Project Name</th>
                <th
                  scope="col"
                  style="width: 15%;"
                  class="text-right"
                >
                  <a
                    href="javascript:;"
                    class="showHideAllBtn"
                    @click="$store.commit('toggleShowAllProjects')">
                      {{ showAllProjects ? 'Only active' : 'Show All' }}
                  </a>
                  <plus-box-outline class="icons text-white" @click="isAddNewProjectModalVisible = !isAddNewProjectModalVisible" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="project in allProjects" :key="project">
                <td class="text-center">{{ project.year }}</td>
                <td class="text-center">{{ project.responsibleUserId }}</td>
                <td class="text-center">{{ 10 > project.customerId ? '0' + project.customerId : project.customerId }}</td>
                <td class="text-center">{{ 10 > project.id ? '0' + project.id : project.id }}</td>
                <td>
                  <span
                    :class="['activeIndicator', project.active ? 'active' : 'inactive']"
                    @click="toggleActive(project)"
                  >
                    <span class="ball"></span>
                  </span>
                </td>
                <td>{{ project.projectName }}</td>
                <td class="text-right">
                  <pencil-outline class="icons text-secondary" @click="isEditProjectModalVisible = !isEditProjectModalVisible; editProjectData = project;" />
                  <trash-can-outline class="icons text-danger" @click="deleteProject(project.id)" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-xl-4">
          <table class="table table-sm table-striped table-borderless">
            <thead>
              <tr>
                <th
                  scope="col"
                  style="width: 5%"
                  class="text-center"
                >
                  #
                </th>
                <th scope="col" style="width: 60%">Customer</th>
                <th
                  scope="col"
                  style="width: 35%"
                  class="text-right"
                >
                  <plus-box-outline class="icons text-white" @click="isAddCustomerModalVisible = !isAddCustomerModalVisible" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="customer in allCustomers" :key="customer">
                <td scope="row" class="text-center">{{ customer.id }}</td>
                <td>{{ customer.customerName }}</td>
                <td class="text-right">
                  <pencil-outline class="icons text-secondary" @click="isEditCustomerModalVisible = !isEditCustomerModalVisible; editcustomer = customer;" />
                  <trash-can-outline class="icons text-danger" @click="deleteCustomer(customer.id)" />
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-sm table-striped table-borderless">
            <thead>
              <tr>
                <th
                  scope="col"
                  style="width: 5%"
                  class="text-center"
                >
                  #
                </th>
                <th scope="col" style="width: 60%">Responsible Users</th>
                <th
                  scope="col"
                  style="width: 35%"
                  class="text-right"
                >
                  <plus-box-outline class="icons text-white" @click="isAddNewResponsibleUserModalVisible = !isAddNewResponsibleUserModalVisible" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="responsibleUser in allResponsibleUsers" :key="responsibleUser">
                <td scope="row" class="text-center">{{ responsibleUser.id }}</td>
                <td>{{ responsibleUser.name }}</td>
                <td class="text-right">
                  <pencil-outline class="icons text-secondary" @click="isEditResponsibleUserModalVisible = !isEditResponsibleUserModalVisible; editresponsibleuser = responsibleUser;" />
                  <trash-can-outline class="icons text-danger" @click="deleteResponsibleUser(responsibleUser.id)" />
                </td>
              </tr>
            </tbody>
          </table>
          <transition name="animation">
            <addNewProjectModal
              v-if="isAddNewProjectModalVisible"
              :selected-company="selectedCompany"
              :responsible-users="allResponsibleUsers"
              :customers="allCustomers"
              @close="isAddNewProjectModalVisible = !isAddNewProjectModalVisible"
            />
          </transition>
          <transition name="animation">
            <editProjectModal
              v-if="isEditProjectModalVisible"
              :selected-company="selectedCompany"
              :responsible-users="allResponsibleUsers"
              :customers="allCustomers"
              :edit-project-data="editProjectData"
              @close="isEditProjectModalVisible = !isEditProjectModalVisible"
            />
          </transition>
          <transition name="animation">
            <addCustomerModal
              v-if="isAddCustomerModalVisible"
              :selected-company="selectedCompany"
              @close="isAddCustomerModalVisible = !isAddCustomerModalVisible"
            />
          </transition>
          <transition name="animation">
            <editCustomerModal
              v-if="isEditCustomerModalVisible"
              :selected-company="selectedCompany"
              :editcustomer="editcustomer"
              @close="isEditCustomerModalVisible = !isEditCustomerModalVisible"
            />
          </transition>
          <transition name="animation">
            <addResponsibleUserModal
              v-if="isAddNewResponsibleUserModalVisible"
              :selected-company="selectedCompany"
              @close="isAddNewResponsibleUserModalVisible = !isAddNewResponsibleUserModalVisible"
            />
          </transition>
          <transition name="animation">
            <editResponsibleUserModal
              v-if="isEditResponsibleUserModalVisible"
              :selected-company="selectedCompany"
              :editresponsibleuser="editresponsibleuser"
              @close="isEditResponsibleUserModalVisible = !isEditResponsibleUserModalVisible"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PlusBoxOutline, PencilOutline, TrashCanOutline } from 'mdue'
import addCustomerModal from '../components/addCustomerModal.vue'
import editCustomerModal from '../components/editCustomerModal.vue'
import addResponsibleUserModal from '../components/addResponsibleUserModal.vue'
import editResponsibleUserModal from '../components/editResponsibleUserModal.vue'
import addNewProjectModal from '../components/addNewProject.vue'
import editProjectModal from '../components/editProjectModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    PlusBoxOutline,
    PencilOutline,
    TrashCanOutline,
    addCustomerModal,
    editCustomerModal,
    addResponsibleUserModal,
    editResponsibleUserModal,
    addNewProjectModal,
    editProjectModal,
  },
  data () {
    return {
      selectedCompany: '',
      isAddCustomerModalVisible: false,
      isEditCustomerModalVisible: false,
      isAddNewResponsibleUserModalVisible: false,
      isEditResponsibleUserModalVisible: false,
      isAddNewProjectModalVisible: false,
      isEditProjectModalVisible: false,
      editcustomer: '',
      editresponsibleuser: '',
      editProjectData: '',
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'getAllProjects',
      'showAllProjects',
      'projectsOrderBy'
    ]),
    allUserCompanies () {
      return this.$store.state.userCompanies
    },
    allCustomers () {
      return this.$store.state.allCustomers
    },
    allResponsibleUsers () {
      return this.$store.state.allResponsibleUsers
    },
    allProjects () {
      return this.getAllProjects.filter(project => {
        return this.showAllProjects || (project.active === 1 || project.progress)
      })
    },
    reloadIfAnyOfThoseChange () {
      return `${this.selectedCompany}-${this.showAllProjects}-${this.projectsOrderBy.field}-${this.projectsOrderBy.direction}`
    }
  },
  watch: {
    reloadIfAnyOfThoseChange: function () {
      this.$store.dispatch('getProjects', {
        companyId: this.selectedCompany
      })
      this.$store.dispatch('getCustomers', this.selectedCompany)
      this.$store.dispatch('getResponsibleUsers', this.selectedCompany)
    },
    allUserCompanies: function (to, from) {
      if (from === null || to.length) {
        this.selectedCompany = this.allUserCompanies[0].id
      }
    }
  },
  created () {
    this.$store.dispatch('getUserCompanies', this.$store.state.user)
  },
  methods: {
    async logOut () {
      await this.$store.dispatch('logout')
        .then(() => this.$router.push('/'))
    },
    deleteCustomer (customerId) {
      if(confirm('are you sure you want to delete this Customer?'))
      {this.$store.dispatch('deleteCustomer', customerId)
        .then(() => this.$store.dispatch('getCustomers', this.selectedCompany))}
    },
    deleteResponsibleUser (responsibleUserId) {
      if(confirm('are you sure you want to delete this Responsible User?'))
      {this.$store.dispatch('deleteResponsibleUser', responsibleUserId)
        .then(() => this.$store.dispatch('getResponsibleUsers', this.selectedCompany))}
    },
    deleteProject (projectId) {
      if(confirm('are you sure you want to delete this Project?'))
      {this.$store.dispatch('deleteProject', projectId)
        .then(() => this.$store.dispatch('getProjects', {
          companyId: this.selectedCompany
        }))}
    },
    toggleActive (project) {
      project.active = project.active ? 0 : 1
      project.progress = true

      this.$store.dispatch('editProject', {
        payload: {
          projectId: project.id,
          active: project.active
        }
      })
        .catch(() => {
          project.active ^= 1
        })
        .finally(() => {
          delete project.progress
        })

    },

    sortBy (field) {
      this.$store.commit('changeProjectsOrderBy', {
        field,
        direction: this.projectsOrderBy.field === field ? (this.projectsOrderBy.direction === 'asc' ? 'desc' : 'asc') : this.projectsOrderBy.direction
      })
    }
  }
}
</script>

